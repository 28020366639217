import { ethers } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';

import { injectedConnector, walletConnectConnector } from '../util/connectors';
import { POLLING_INTERVAL, ConnectorNames } from '../config/constant';

export const connectorsByName = {
    [ConnectorNames.Injected]: injectedConnector,
    [ConnectorNames.WalletConnect]: walletConnectConnector,
};
export const getLibrary = (provider) => {
    const library = new Web3Provider(provider)
    library.pollingInterval = POLLING_INTERVAL;
    return library;
};

// export const signMessage = async (provider, account, message) => {
//     var _a, _b;
//     if (window.BinanceChain) {
//         const { signature } = await window.BinanceChain.bnbSign(account, message);
//         return signature;
//     }
//     /**
//      * Wallet Connect does not sign the message correctly unless you use their method
//      * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
//      */
//     if ((_a = provider.provider) === null || _a === void 0 ? void 0 : _a.wc) {
//         const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message));
//         const signature = await ((_b = provider.provider) === null || _b === void 0 ? void 0 : _b.wc.signPersonalMessage([wcMessage, account]));
//         return signature;
//     }
//     return provider.getSigner(account).signMessage(message);
// };