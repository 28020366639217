import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import getNodeUrl from './getRpcUrl';
import { POLLING_INTERVAL } from '../config/constant';

const rpcUrl = getNodeUrl();
const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

export const injectedConnector = new InjectedConnector({ supportedChainIds: [chainId] });

export const walletConnectConnector = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

