import React from "react";

import RarityControlSection from "../components/RarityControlSection";

function WalletPage(props) {
  return (
    <RarityControlSection
      textColor="white"
      variant="dark"
      title="Rarity Control"
      subtitle=""
    />
  );
}

export default WalletPage;
