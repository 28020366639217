import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";

import fetchRarity from "../../api/rarity-fetch";

import "./WalletComponent.scss";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function FetchRarityComponent(props) {
  const [isFetching, setIsFetching] = useState(false);
  const [contractAddressToFetch, setContractAddressToFetch] = useState("");

  const { addToast } = useToasts();

  const submitFetchRarity = async (e) => {
    e.preventDefault();

    setIsFetching(true);
    try {
      await fetchRarity(contractAddressToFetch, {
        mode: "safe",
        force: true,
      });
      addToast("Started to fetch rarity! sit back!", {
        appearance: "info",
        autoDismiss: true,
      });
    } catch (error) {
      console.log("erorr->", error);
    }
  };

  return (
    <Row
      style={{
        border: "1px solid white",
        borderRadius: "8px",
        padding: "20px",
        margin: "40px 0px",
      }}
    >
      <Col>
        <h2>Fetch Rarity</h2>
        <div>
          <Form onSubmit={submitFetchRarity}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>NFT Contract Address</Form.Label>
              <Form.Control
                placeholder="Enter NFT contract Address"
                required
                value={contractAddressToFetch}
                onChange={({ target: { value } }) =>
                  setContractAddressToFetch(value)
                }
              />
            </Form.Group>
            <Button block type="submit" disabled={isFetching}>
              Fetch Rarity
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default FetchRarityComponent;
