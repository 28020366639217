import React from "react";

import { Container, Row, Col } from 'react-bootstrap';

function NotFoundPage(props) {
  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              padding: "50px",
              width: "100%",
              textAlign: "center",
            }}
          >
            The page <code>{props.location.pathname}</code> could not be found
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFoundPage;
