import React from "react";
import { scroller } from "react-scroll";
import Container from "react-bootstrap/Container";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import Section from "./Section";
import { Link } from "./../util/router.js";
import "./Footer.scss";

function Footer(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useLocation();

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 100,
      smooth: true,
      offset: -250,
    });
  }
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="footer"
    >
      <Container>
        <div className="FooterComponent__inner">
          <div className="brand left" style={{
            marginTop: isMobile ? '50px' : '0px'
          }}>
            <Link to="/" onClick={() => scrollToSection('herosection')}>
              <img
                src={props.logo} alt="Logo"
                height="30"
                style={{
                  height: '40px',
                  borderRadius: '50%',
                  marginRight: isMobile ? '0px' : '7px',
                  display: 'inline-block',
                  boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.15)'
                }}
              />
            </Link>
          </div>
          {location.pathname === '/' ? (
            <div className="links right">
              {/* <Link to="/">Mint</Link>
              <Link to="#" onClick={() => scrollToSection('aboutsection')}>About</Link>
              <Link to="#" onClick={() => scrollToSection('howtoplaysection')}>How to Play</Link>
              <Link to="#" onClick={() => scrollToSection('roadmapsection')}>Roadmap</Link>
              <Link to="#" onClick={() => scrollToSection('teamsection')}>Team</Link>
              <Link to="#" onClick={() => scrollToSection('faqsection')}>FAQs</Link> */}
            </div>
          ) : (
            <div className="links right">
              <Link to="/">Home</Link>
            </div>
          )}
          <div className="social right">
            <a
              href="https://discord.gg/bgXYgtepFf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-discord" />
              </span>
            </a>
            <a
              href="https://twitter.com/WhaleTogether"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <i className="fab fa-twitter" />
              </span>
            </a>
          </div>
          <div className="copyright left" style={{ color: 'black' }}>{props.copyright}</div>
        </div>
      </Container>
    </Section>
  );
}

export default Footer;
