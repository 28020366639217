import React from "react";
import { Web3ReactProvider } from '@web3-react/core';
import {IntlProvider} from 'react-intl';
import { ToastProvider } from 'react-toast-notifications';
import { Redirect } from "react-router-dom";

import "./../styles/global.scss";
import RarityControlPage from "./rarity-control";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import "./../util/analytics.js";
import { QueryClientProvider } from "./../util/db.js";
import { getLibrary } from "./../util/web3React";
import MetamaskProvider from '../providers/MetamaskProvider';

import NavbarCustom from "../components/NavbarCustom";
import Footer from "./../components/Footer";
import logoWhite from "../images/whaletogether-mock.jpg";

function App(props) {
  const companyName = 'WhaleTogether';
  return (
    <IntlProvider locale="en-US">
      <ToastProvider placement="bottom-left">
        <Web3ReactProvider getLibrary={getLibrary}>
          <MetamaskProvider>
            <QueryClientProvider>
              <Router>
                <NavbarCustom
                  bg="black"
                  variant="dark"
                  expand="md"
                  logo={logoWhite}
                />
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/rarity" />
                  </Route>
                  <Route exact path="/rarity" component={RarityControlPage} />
                  <Route component={NotFoundPage} />
                </Switch>
                <Footer
                  bg="white"
                  textColor="dark"
                  size="md"
                  bgImage=""
                  bgImageOpacity={1}
                  copyright={`© ${new Date().getFullYear()} ${companyName}`}
                  logo={logoWhite}
                />
              </Router>
            </QueryClientProvider>
          </MetamaskProvider>
        </Web3ReactProvider>
      </ToastProvider>
    </IntlProvider>
  );
}

export default App;
