import Metamask from "../components/Svg/Icons/Metamask";
import WalletConnect from "../components/Svg/Icons/WalletConnect";
import TrustWallet from "../components/Svg/Icons/TrustWallet";
import Coin98 from "../components/Svg/Icons/Coin98";

export const POLLING_INTERVAL = 12000;
export const ConnectorNames = {
  Injected: 'injected',
  WalletConnect: 'walletconnect',
}

export const connectorsConfig = [
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    priority: 1,
  },
  // {
  //   title: "WalletConnect",
  //   icon: WalletConnect,
  //   connectorId: ConnectorNames.WalletConnect,
  //   priority: 2,
  // },
  // {
  //   title: "Trust Wallet",
  //   icon: TrustWallet,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 3,
  // },
  // {
  //   title: "Coin98",
  //   icon: Coin98,
  //   connectorId: ConnectorNames.Injected,
  //   priority: 999,
  // },
];

export const CONNECTOR_LOCAL_STORAGE_KEY = "connectorId";
export const WALLET_LOCAL_STORAGE_KEY = "wallet";