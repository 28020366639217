import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications';

import syncRarity from "../../api/rarity-sync";

import "./WalletComponent.scss";


function SyncRarityComponent(props) {
  const [isSyncing, setIsSyncing] = useState(false);
  const [contractAddressToSync, setContractAddressToSync] = useState('');
  const [syncSource, setSyncSource] = useState('rs');
  const [collectionSlug, setCollectionSlug] = useState('');

  const { addToast } = useToasts();

  const submitSyncRarity = async (e) => {
    e.preventDefault();

    setIsSyncing(true);
    try {
      syncRarity(contractAddressToSync, {
        source: syncSource,
        collectionSlug,
      })
      addToast('Started to fetch rarity! sit back!', {
        appearance: 'info',
        autoDismiss: true,
      });
    } catch (error) {
      console.log('erorr->', error);
    }
  }

  return (
    <Row style={{
      border: '1px solid white',
      borderRadius: '8px',
      padding: '20px',
      margin: '40px 0px',
    }}>
      <Col>
        <h2>Sync Rarity</h2>
        <div>
          <Form onSubmit={submitSyncRarity}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                NFT Contract Address
              </Form.Label>
              <Form.Control
                placeholder="Enter NFT contract Address"
                required
                value={contractAddressToSync}
                onChange={({target: { value }}) => setContractAddressToSync(value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Source
              </Form.Label>
              <Form.Control
                as="select"
                required
                value={syncSource}
                onChange={e => {
                  console.log("e.target.value", e.target.value);
                  setSyncSource(e.target.value);
                }}
              >
                <option value="rs">RS</option>
                <option value="rt">RT</option>
                <option value="rsniffer">RSniffer</option>
                <option value="meta">MetaR</option>
                <option value="rmon">RMon</option>
                <option value="thelittles">Littles</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Collection Slug
              </Form.Label>
              <Form.Control
                placeholder="Enter collection slug"
                required
                value={collectionSlug}
                onChange={({target: { value }}) => setCollectionSlug(value)}
              />
            </Form.Group>
            <Button block type="submit" disabled={isSyncing}>
              Sync Rarity
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default SyncRarityComponent;