import React, { useEffect, useState } from 'react'

import useActiveWeb3React from '../hooks/useActiveWeb3React';
import useAuth from '../hooks/useAuth';
import { injectedConnector } from '../util/connectors'
import { CONNECTOR_LOCAL_STORAGE_KEY } from '../config/constant';

function MetamaskProvider({ children }) {
  const { active: networkActive, error: networkError, activate: activateNetwork } = useActiveWeb3React();
  const { login } = useAuth();

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const connectorLocalStorageKey = localStorage.getItem(CONNECTOR_LOCAL_STORAGE_KEY);
    injectedConnector
      .isAuthorized()
      .then((isAuthorized) => {
        setLoaded(true)
        if (isAuthorized && !networkActive && !networkError && connectorLocalStorageKey) {
          login(connectorLocalStorageKey);
        }
      })
      .catch(() => {
        setLoaded(true)
      })
  }, [activateNetwork, login, networkActive, networkError])
  if (loaded) {
    return children
  }
  return <>Loading</>
}

export default MetamaskProvider