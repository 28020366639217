import React, { useEffect, useState } from "react";
import { scroller } from "react-scroll";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";

import WalletModal from "../WalletModal";

import useAuth from '../../hooks/useAuth';
import { shorter } from "../../util/util";

function NavbarCustom(props) {
  let listener = null
  const { logout } = useAuth();
  
  const [scrollState, setScrollState] = useState('top');
  const [isCollapse, setIsCollapse] = useState(true);
  const [modalState, setModalState] = useState(false);
  const toggleNavbar = () => {
    setIsCollapse(!isCollapse);
  }

  const location = useLocation();

  useEffect(() => {
    listener = document.addEventListener('scroll', e => {
      const scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 60) {
        if (scrollState !== "scrolled") {
          setScrollState("scrolled")
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top")
        }
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [scrollState])

  const onDisconnectWallet = () => {
    logout();
  }

  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 100,
      smooth: true,
      offset: -250,
    });
  }

  const toggleModal = () => {
    setModalState(!modalState);
  }

  return (
    <>
      <WalletModal open={modalState} onClose={toggleModal} />
      <Navbar 
        bg={props.bg} 
        variant={props.variant}
        expand={props.expand}
        fixed="top"
        style={{ padding: '10px' }}
      >
        <Container>
          <LinkContainer to="/" onClick={() => scrollToSection('herosection')}>
            <Navbar.Brand href="#home" className="mr-auto">
              <img
                className="d-inline-block align-center"
                src={props.logo}
                alt="Logo"
                height="50"
                style={{
                  borderRadius: '50%',
                  marginRight: '7px',
                  display: 'inline-block',
                }}
              />{'  '}
              <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <h1 style={{ fontSize: '20px', marginBottom: '2px' }}>WhaleSniper</h1>
                  <h6 style={{ fontSize: '13px', margin: '0px' }}>by WhaleTogether</h6>
                </div>
              </div>
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="navbar-nav" className="border-0" onClick={() => toggleNavbar()} />
          {/* <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav className="mr-1">  
              {active ? (
                <>
                  <NavDropdown
                    title={`${shorter(account)}`} 
                    id="basic-nav-dropdown" 
                    style={{ marginLeft: '8px', border: 'solid 1px white', borderRadius: '5px', fontWeight: '600' }}
                    className="text-center"
                  >
                    <NavDropdown.Item
                      href="/wallet"
                    >
                      Wallet
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onClick={onDisconnectWallet}
                    >
                      Disconnect
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Button
                  style={{ 
                    marginLeft: '8px',
                    background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
                    fontWeight: '600',
                  }} 
                  variant="primary"
                  onClick={toggleModal}
                  disabled={+ new Date('2021-10-28T09:00:00.000-06:00') > Date.now()}
                >Connect Wallet</Button>
              )}
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarCustom;
