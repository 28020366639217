import fetch from "node-fetch";

const WTG_BASE_URL = process.env.REACT_APP_WTG_BACKEND_BASE_URL;
const WTG_API_KEY = process.env.REACT_APP_WTG_API_KEY;

/**** RARITY ****/
// Create a new user
function fetchRarity(contractAddress, data) {
  return fetch(`${WTG_BASE_URL}/api/v1/collections`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${WTG_API_KEY}`,
    },
    body: JSON.stringify({ contractAddress, ...data }),
  }).then((r) => r.json());
}

// Update an existing user
function syncRarity(contractAddress, data) {
  return fetch(`${WTG_BASE_URL}/api/v1/collections/${contractAddress}/sync`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${WTG_API_KEY}`,
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());
}

export { fetchRarity, syncRarity };
