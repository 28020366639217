import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import SectionHeader from "../SectionHeader";
import Section from "./Section";
import FetchRarityComponent from './FetchRarityComponent';
import SyncRarityComponent from './SyncRarityComponent';

import WalletModal from "../WalletModal";


function WalletSection(props) {

  const [modalState, setModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  }

  return (
    <>
      <WalletModal open={modalState} onClose={toggleModal} />
      <Section
        bg={props.bg}
        textColor={props.textColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        style={{
          paddingBottom: '0px !important',
          background: 'linear-gradient(63deg, #FF2079 0%, #440BD4 100%)',
        }}
      >
        <Container style={{ padding: '100px 35px 0px' }}>
          <FetchRarityComponent />
          <SyncRarityComponent />
        </Container>
      </Section>
    </>
  );
}

export default WalletSection;
